<template>
  <div
    v-if="isShow"
    :class="classes"
    @click="handleClick"
  >
    <span
      v-show="!isLoading"
      class="bsc-cart-item-add-more__text"
    >
      {{ text }}
    </span>
    <BallLoading
      v-if="isLoading"
    />
  </div>
</template>

<script name="AddMore" setup lang="ts">
import { computed } from 'vue'
import BallLoading from '../../components/ball-loading.vue'
import type { AddMore } from '../../../../types/laptop/index.ts'

/**
 * 商品行加车组件
 */

// events
const emit = defineEmits(['click'])

// hooks
const props = withDefaults(defineProps<AddMore.Props>(), {
  isShow: false,
  isLoading: false,
  text: '',
})

// data
const classes = computed(() => {
  return {
    'bsc-cart-item-add-more__wrap': true,
    'bsc-cart-item-add-more__wrap_loading': props.isLoading,
  }
})

// methods
const handleClick = (e) => {
  if (props.isLoading) {
    return
  }
  emit('click', e)
}
</script>

<style lang="less">
.bsc-cart-item-add-more {
  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    padding: 4px 10px;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 16px;
    &:hover {
      border-color: #222;
      cursor: pointer;
    }
  }
  &__text {
    font-size: 12px;
    font-weight: 600;
    color: #222;
  }
}
</style>
