import ChangeMall from './change-mall.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useChangeMall = createHelper('ChangeMall', getProps)

export {
  ChangeMall,
  useChangeMall,
}
