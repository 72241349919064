import GoodsSaleAttr from './goods-sale-attr.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsSaleAttr = createHelper('GoodsSaleAttr', getProps)

export {
  GoodsSaleAttr,
  useGoodsSaleAttr,
}
