<template>
  <div class="bsc-cart-item-discount-cell">
    <div class="bsc-cart-item-discount-cell__content">
      <div
        class="bsc-cart-item-discount-cell__left-tip"
        :class="{ 'bsc-cart-item-discount-cell__left-tip_bold': leftTipBold }"
      >
        {{ data.leftKeyTip }}:
      </div>
      <div
        class="bsc-cart-item-discount-cell__right-price"
        :class="rightPriceClassname"
      >
        {{ data.rightValuePrice.amountWithSymbol }}
      </div>
    </div>
    <div
      v-if="data.additionalDesc && data.additionalCrossedPrice"
      class="bsc-cart-item-discount-cell__sub"
    >
      <span>{{ data.additionalDesc }}&nbsp;</span>
      <span class="bsc-cart-item-discount-cell__line-through">
        {{ data.additionalCrossedPrice.amountWithSymbol }}
      </span>
    </div>
    <div
      v-if="data.questionMark"
      class="bsc-cart-item-discount-cell__tip"
    >
      {{ data.questionMark }}
    </div>
  </div>
</template>

<script name="DiscountCell" setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  leftTipBold: {
    type: Boolean,
    default: false,
  },
})

const rightPriceClassname = computed(() => {
  return `style-type__${props.data.styleType}`
})
</script>

<style lang="less">
.bsc-cart-item-discount-cell {
  margin: 8px 0;
  &__content {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    line-height: normal;
  }

  &__left-tip {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    &_bold {
      font-weight: 700;
    }
  }

  &__right-price {
    flex-shrink: 0;
    position: relative;
    max-width: 65%;
    font-weight: 700;
    text-align: right;
    word-break: break-all;
  }

  &__tip {
    margin-top: 4px;
    font-size: 10px;
    color: rgba(#000, .3);
  }

  &__sub {
    font-size: 10px;
    color: @sui_color_gray_light1;
  }

  &__line-through {
    text-decoration: line-through;
  }

  .style-type {
    &__1 {
      color: #000;
    }

    &__2 {
      color: #FA6338;
    }

    &__3 {
      color: #000;
    }
  }
}
</style>
