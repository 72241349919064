<template>
  <div
    v-if="isShow"
    :class="classes"
    :style="style"
    @click="handleClick"
  >
    <img
      class="bsc-cart-item-goods-store__logo"
      :src="storeLogo"
    />
    <span class="bsc-cart-item-goods-store__text">
      {{ storeTitle }}
    </span>
    <sui_icon_more_right_12px_1
      v-if="isClick"
      class="bsc-cart-item-goods-store__icon-more"
      size="12px"
    />
  </div>
</template>

<script name="GoodsStore" setup lang="ts">
import { computed, watch } from 'vue'
import sui_icon_more_right_12px_1 from '../../components/icon/sui_icon_more_right_12px_1.vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import type { GoodsStore } from '../../../../types/laptop/index.ts'

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['click', 'report', 'expose'])

const props = withDefaults(defineProps<GoodsStore.Props>(), {
  isShow: false,
  isShowMask: false,
  isClick: false,
  storeTitle: '',
  storeLogo: '',
  storeIconSize: '16px',
  textFontSize: '13px',
  textFontWeight: 'normal',
  maxWidth: '100%',
})

const classes = computed(() => [
  'bsc-cart-item-goods-store',
  {
    'bsc-cart-item-goods-store__pointer': props.isClick,
  },
])

const style = computed(() => {
  return {
    '--font-size': props.textFontSize,
    '--font-weight': props.textFontWeight,
    '--store-icon-size': props.storeIconSize,
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
    maxWidth: props.maxWidth,
  }
})

const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('GoodsStore', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-goods-store {
  display: flex;
  align-items: center;
  opacity: var(--mask-opacity);

  &__pointer {
    cursor: pointer;
  }

  &__logo {
    width: var(--store-icon-size);
    height: var(--store-icon-size);
    margin-right: 4px;
  }

  &__text {
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__icon-store, &__icon-preferred-seller {
    flex-shrink: 0;
    margin-right: 2px;
  }

  &__icon-preferred-seller {
    width: var(--store-icon-font-size);
    height: var(--store-icon-font-size);
  }

  &__icon-more {
    flex-shrink: 0;
    margin-left: 2px;
  }
}
</style>
