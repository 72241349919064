import GoodsPriceV1 from './goods-price-v1.vue'
import GoodsPriceV2 from './goods-price-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsPrice = createHelper('GoodsPrice', getProps)

export {
  GoodsPriceV1,
  GoodsPriceV2,
  useGoodsPrice,
}
