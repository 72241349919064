import GoodsAddWish from './goods-add-wish.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsAddWish = createHelper('GoodsAddWish', getProps)

export {
  GoodsAddWish,
  useGoodsAddWish,
}
