<template>
  <span
    :class="needBgClip ? 'bsc-cart-item-qw-num-box-clip' : 'bsc-cart-item-qw-num-box' "
    :style="{ height: height+'px', 'line-height': height+'px' }"
  >
    <span
      ref="trasBox"
      class="bsc-cart-item-tras-box"
      :class="[isDelay ? 'bsc-cart-item-tras-box__delay' : 'bsc-cart-item-tras-box__transition']"
      :style="!needBgClip ? { top:( -1 * value * height) +'px' } : {}"
    >
      <span
        class="qwnum"
        :style="{ height: height+'px', 'line-height': height+'px' }"
      >0</span>
      <span
        v-for="i in 9"
        :key="i"
        :style="{ height: height+'px', 'line-height': height+'px' }"
      >{{ i }}</span><br />
    </span>
  </span>
</template>
<script lang="ts">
export default {
  name: 'NumRun',
  props: {
    value: {
      type: Number,
      default() {
        return 0
      },
    },
    isDelay: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 20,
    },
    // 如果数字颜色是过渡色 需要传入true
    // 原来是用top控制数字位置，需要传入true 用margin-top去控制数字位置
    needBgClip: {
      type: Boolean,
      default: false,
    },
    bgClipDelay: {
      type: Number,
      default: 0,
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.needBgClip) {
      let timer = null
      if (this.bgClipDelay) {
        // @ts-ignore
        timer = setTimeout(() => {
          // @ts-ignore
          this.$refs.trasBox.style.marginTop = `${-1 * this.value * this.height}px`
          // @ts-ignore
          clearTimeout(timer)
        }, this.bgClipDelay)
      } else {
        // @ts-ignore
        timer = setTimeout(() => { // 修复PC动画不会执行（偶现）
          // @ts-ignore
          this.$refs.trasBox.style.marginTop = `${-1 * this.value * this.height}px`
          // @ts-ignore
          clearTimeout(timer)
        })
        // this.$nextTick(() => {
        //   this.$refs.trasBox.style.marginTop = `${-1 * this.value * this.height}px`
        // })
      }
    }
  },
}
</script>
<style lang="less">
/*postcss-shein:ignore*/
.bsc-cart-item-qw-num-box {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 8px;
  height: 20px;
  line-height: 20px;
  top: 5px;

  .bsc-cart-item-tras-box {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    width: 100%;
    transform-origin: 0 0;
    transition: top 0.8s;
    span{
      display: block;
      height: 20px;
      line-height: 20px;
    }
    &.bsc-cart-item-tras-box-ar{
      right:0;
    }
    &.bsc-cart-item-tras-box-normal{
      left:0;
    }
  }
  .bsc-cart-item-tras-box__transition {
    transition: top 0.8s;
  }
  .bsc-cart-item-tras-box__delay {
    transition-delay: .8s;
  }
}

.bsc-cart-item-qw-num-box-clip {
  // position: relative;
  display: inline-block;
  overflow: hidden;
  width: 8px;
  height: 20px;
  line-height: 20px;
  top: 4px;

  .bsc-cart-item-tras-box {
    display: block;
    // position: absolute;
    top: 0;
    height: auto;
    width: 100%;
    transform-origin: 0 0;
    transition: all 1s;
    span{
      display: block;
      height: 20px;
      line-height: 20px;
    }
    &.bsc-cart-item-tras-box-ar{
      right:0;
    }
    &.bsc-cart-item-tras-box-normal{
      left:0;
    }
  }
  .bsc-cart-item-tras-box__transition {
    transition: all 1s;
  }
  .bsc-cart-item-tras-box__delay {
    transition-delay: .5s;
  }
}
</style>
