<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-goods-custom-content"
    @click="handleClick"
  >
    <div>{{ btnText }}</div>
    <sui_icon_more_right_12px />
  </div>
</template>

<script name="GoodsCustomContent" setup lang="ts">
import { computed } from 'vue'
import sui_icon_more_right_12px from '../../components/icon/sui_icon_more_right_12px.vue'
import type { GoodsCustomContent } from '../../../../types/laptop/index.ts'

/**
 * 商品行定制商品主图组件
 */

// events
const emit = defineEmits(['click', 'report'])

// props
const props = withDefaults(defineProps<GoodsCustomContent.Props>(), {
  isShow: false,
  isShowMask: false,
  btnText: '',
  isClick: true,
})

// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})
// events
const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}
</script>

<style lang="less">
.bsc-cart-item-goods-custom-content {
  height: 24px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background: #f6f6f6;
  color: #222;
  padding: 0 4px 0 6px;
  gap: 4px;
  font-size: 12px;
  opacity: var(--mask-opacity);
}
</style>
