<template>
  <span style="direction: ltr; unicode-bidi: bidi-override;display: inline-flex;align-items: baseline;">
    <template v-for="(i, j) in transformAmount">
      <num-run
        v-if="parseInt(i) >= 0"
        :key="j"
        :value="parseInt(i)"
        :style="{ width: boxWidth + 'px' }"
        :height="boxHeight"
        :need-bg-clip="needBgClip"
        :bg-clip-delay="bgClipDelay"
        :is-delay="isDelay"
        :css-right="cssRight"
      />
      <span
        v-else
        :key="i+j"
      >{{ i }}</span>
    </template>
  </span>
</template>

<script lang="ts">
import numRun from './numRun.vue'
export default {
  name: 'NumberRoll',
  components: {
    numRun,
  },
  props: {
    amount: {
      type: [String, Number],
      default() {
        return ''
      },
    },
    boxWidth: {
      type: Number,
      default() {
        return 8
      },
    },
    boxHeight: {
      type: Number,
      default: 20,
    },
    isDelay: {
      type: Boolean,
      default: false,
    },
    needBgClip: {
      type: Boolean,
      default: false,
    },
    bgClipDelay: {
      type: Number,
      default: 0,
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    transformAmount(){
      return (this.amount + '').split('')
    },
  },
}
</script>
