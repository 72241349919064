import GoodsStore from './goods-store.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsStore = createHelper('GoodsStore', getProps)

export {
  GoodsStore,
  useGoodsStore,
}
