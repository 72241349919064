<template>
  <div
    :class="[
      'bsc-cart-item-goods-qty-option',
      {
        'bsc-cart-item-goods-qty-option_selected': isSelected,
        'bsc-cart-item-goods-qty-option_disabled': disabled,
      },
    ]"
  >
    <span class="bsc-cart-item-goods-qty-option__label">{{ label }}</span>
    <sui_icon_selected_16px
      v-if="isSelected"
      size="14px"
      :class="[
        'bsc-cart-item-goods-qty-option__icon',
        {
          'bsc-cart-item-goods-qty-option__icon_disabled': disabled,
        },
      ]"
    />
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import sui_icon_selected_16px from '../../components/icon/sui_icon_selected_16px.vue'

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  value: {
    type: [String, Number],
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selectValue: {
    type: [String, Number],
    default: '',
  },
  maxOptionNumber: {
    type: Number,
    default: 10,
  },
})
const isSelected = computed(() => props.value == props.selectValue || (props.value == '' && props.selectValue > props.maxOptionNumber))
</script>

<style lang="less">
.bsc-cart-item-goods-qty-option {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  font-family: Arial, system-ui;
  color: #222;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background-color: #F2F2F2;
  }
  &.bsc-cart-item-goods-qty-option_selected {
    .bsc-cart-item-goods-qty-option__label {
      font-weight: 700;
    }
  }
  &.bsc-cart-item-goods-qty-option_disabled {
    cursor: not-allowed;
    background-color: #F6F6F6;
    &:hover {
      background-color: #F6F6F6;
    }
    .bsc-cart-item-goods-qty-option__label {
      color: #CCC;
    }
  }

  .bsc-cart-item-goods-qty-option__icon {
    /* stylelint-disable-next-line declaration-no-important */
    color: #000!important;
  }
  .bsc-cart-item-goods-qty-option__icon_disabled {
    /* stylelint-disable-next-line declaration-no-important */
    color: #CCC!important;
  }
}
</style>
