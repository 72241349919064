/**
 * 判断元素是否在可视区域
 */
export const isInViewPort = (element, isIntersecting = false) => {
  if (!element) return
  const viewHeight =
    window.innerHeight || document.documentElement.clientHeight
  const { top, bottom, height } = element.getBoundingClientRect()
  const EXPOSURE_HEIGHT = height * 0.6
  const EXTRA_HEIGHT = 800

  let isInViewPort = false
  if (height === 0) {
    return false
  }
  if (isIntersecting) {
    isInViewPort =
      (top >= 0 && top <= viewHeight + EXTRA_HEIGHT) ||
      (top < 0 && bottom >= -EXTRA_HEIGHT)
  } else {
    isInViewPort =
      (top >= 0 && bottom <= viewHeight + EXPOSURE_HEIGHT) ||
      (top < 0 && bottom >= EXPOSURE_HEIGHT)
  }

  return isInViewPort
}
