<template>
  <div
    v-if="isShow"
    :class="classnames"
    :style="style"
    @click="handleClick"
  >
    <div class="bsc-cart-item-promotion-tag__text">
      {{ text }}
    </div>
    <SPopover
      v-if="tip"
      trigger="hover"
      fix-disappear-position
      append-to-body
      :delay-render="300"
      :content="tip"
      :prop-style="{width: '266px', boxShadow: '0px 2px 10px 2px rgba(0,0,0,0.1)', padding: '10px', lineHeight: '18px', whiteSpace: 'normal'}"
    >
      <template #reference>
        <sui_icon_info_12px_1
          size="12px"
          color="#959595"
          class="bsc-cart-item-promotion-tag__icon-tip"
        />
      </template>
    </SPopover>
    <ClientOnly>
      <template v-if="showCountdown">
        <div class="bsc-cart-item-promotion-tag__line"></div>
        <template v-if="displayDateOrTime && dateFormat">
          <div class="bsc-cart-item-promotion-tag__date">
            {{ dateFormat }}
          </div>
        </template>
        <template v-else>
          <CartCountdown
            v-if="timestamp"
            class="bsc-cart-item-promotion-tag__count-down"
            v-bind="cartCountDownProps"
          />
        </template>
      </template>
    </ClientOnly>
    <sui_icon_closed_36px
      v-if="isClick"
      size="12px"
      class="bsc-cart-item-promotion-tag__icon"
    />
  </div>
</template>

<script name="PromotionTag" setup lang="ts">
import { computed, watch } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import sui_icon_info_12px_1 from '../../../components/icon/sui_icon_info_12px_1.vue'
import sui_icon_closed_36px from '../../../components/icon/sui_icon_closed_36px.vue'
import { hexToRgb, timeTransformer } from '@shein/common-function'
import { MESSAGE_DATE } from '../../../utils/date'
import CartCountdown from '../../../components/cart-countdown.vue'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'
import type { PromotionTag } from '../../../../../types/laptop/index.ts'
import { SPopover } from '@shein-aidc/sui-popover/laptop'

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['click', 'report', 'expose'])

const props = withDefaults(defineProps<PromotionTag.Props>(), {
  isShow: false,
  isShowMask: false,
  isClick: false,
  type: '',
  text: '',
  tip: '',
  hideCountdown: false,
  timestamp: 0,
  endInTip: '',
  displayDateOrTime: false,
  data: null,
  textColor: '',
  bgColor: '',
  maxWidth: '100%',
})

const classnames = computed(() => {
  const cls = [
    'bsc-cart-item-promotion-tag',
    {
      'bsc-cart-item-promotion-tag__pointer': props.isClick,
      'has-count-down': showCountdown.value,
    },
  ]
  if (props.type) cls.push(`bsc-cart-item-promotion-tag__${props.type}`)
  return cls
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--text-color': props.textColor,
  '--bg-color': props.bgColor,
  '--rgba-bg-color': hexToRgb({ hex: props.bgColor, opa: props.type === 'flash' ? 0.3 : 0.5 }),
  '--rgba-line-color': hexToRgb({ hex: props.textColor, opa: 0.4 }),
  maxWidth: props.maxWidth,
}))

const dateFormat = computed(() => {
  if (typeof window === 'undefined') return ''

  if (!props.timestamp || !props.endInTip) return ''

  const text = timeTransformer({
    time: props.timestamp * 1000,
    sDateMap: MESSAGE_DATE,
  })
  return `${props.endInTip} ${text}`
})

const showCountdown = computed(() => {
  if (props.hideCountdown || !props.timestamp) return false

  if (props.displayDateOrTime) return !!dateFormat.value

  return true
})

const cartCountDownProps = computed(() => ({
  timestamp: +props.timestamp,
  hideOnZero: true,
  reloadOnZero: true,
  fontColor: '#222',
  colonColor: '#222',
  fontBgColor: '#fff',
}))

const handleClick = () => {
  emit('report', props.data)
  if (props.isClick) {
    emit('click', props.data)
  }
}

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose', props.data)
      }
      registerExpose('PromotionTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-promotion-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  font-size: 12px;
  vertical-align: middle;
  opacity: var(--mask-opacity);
  background: var(--bg-color);

  &.has-count-down {
    background: linear-gradient(90deg, var(--bg-color) 0%, var(--rgba-bg-color) 100%);
  }

  &.bsc-cart-item-promotion-tag__club-gift {
    &.has-count-down {
      background: linear-gradient(90deg, #FFECE9 0%, rgba(#FFECE9, .3) 100%);
    }

    .bsc-cart-item-promotion-tag__line {
      border-left-color: var(--rgba-line-color);
    }

    .bsc-cart-item-promotion-tag__icon {
      color: var(--text-color);
    }
  }

  &.bsc-cart-item-promotion-tag__coupon {
    position: relative;
    color: var(--text-color);
    background: @sui_color_white;
    border: 1px solid var(--text-color);
    border-radius: 1px;
    overflow: hidden;
    mask-image: radial-gradient(circle at 0px 50%, transparent 3px, #fff 3px);

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: var(--text-color);
      border-radius: 50%;
      top: 50%;
      /* rtl:begin:ignore */
      margin-top: -4px;
      left: -5px;
    }
  }

  &__text {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--text-color);
  }

  &__icon-tip {
    margin-left: 4px;
    line-height: 1 !important; /* stylelint-disable-line declaration-no-important */
  }

  &__line {
    flex-shrink: 0;
    margin: 0 6px;
    height: 10px;
    border-left: 1px solid @sui_color_gray_dark3;
  }

  &__count-down {
    flex-shrink: 0;
  }

  &__date {
    flex-shrink: 0;
    color: var(--text-color);
  }

  &__pointer {
    cursor: pointer;
  }
}
</style>
