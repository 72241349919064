import type { GoodsSaleAttr } from '../../../../types/laptop/index.ts'
import { isCustomizationProduct } from '../../utils/isCustomizationProduct'

/**
 * 商品销售属性组件 GoodsSaleAttr
 * GoodsSaleAttr 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }): GoodsSaleAttr.Props {
  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
  // 附属品
  const isAddBuyItem = data.value.aggregateProductBusiness?.isAddBuy === '1'
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 是否赠品/加价购附属品
  const isGiftOrAddItem = isGiftItem || isAddBuyItem
  // 是否自定义商品
  const isCustomGoods = isCustomizationProduct(data.value)
  /**
   * 是否展示重新选择按钮
   * 商品售罄：
   *    1. 有效商品（当前尺码售罄，其他尺码有货）&& 非赠品/加价购附属品 && 非新人商品
   *    2. 付费会员赠品 && 付费会员权益存在
   */
  const showReselect = data.value.aggregateProductBusiness?.changeGoodInfo?.isOtherAttr === '1'

  // 颜色图
  const colorImage = data.value.product?.color_image
  // 尺码
  const goodsAttr = data.value.aggregateProductBusiness?.goodsAttr

  // 是否展示尺码按钮
  const showAttr = data.value.isInvalid != 1 && !!(colorImage || goodsAttr)
  // 是否展示色块图
  // const showColorImg = data.value.product?.main_sale_attr_show_mode == 2 && data.value.product?.color_image && data.value.product?.relatedColor?.length > 0

  // 色块图
  // const colorImage = showColorImg ? data.value.product?.color_image : ''

  // 是否显示蒙层
  let isShowMask = false
  // 是否可点击
  let isClick = true
  if (isCustomGoods || isNewcomerItem || (isGiftItem && isNotChecked)) {
    isShowMask = true
  }
  if (isNewcomerItem || isGiftOrAddItem) {
    isClick = false
  }

  // 是否隐藏箭头，赠品、加价购附属品隐藏
  const hideArrow = isGiftOrAddItem
  return {
    isShow: showReselect || showAttr,
    mode: showAttr ? 'attr' : 'reselect',
    isShowMask,
    isClick,
    hideArrow,
    reselectText: data.value.aggregateProductBusiness?.changeGoodInfo?.buttonTip || language.value.SHEIN_KEY_PC_16381 || '',
    color: showAttr ? '#666' : '#222',
    fontWeight: showAttr ? 'normal' : 'bold',
    colorImage,
    goodsAttr,
  }
}
