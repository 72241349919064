<template>
  <SPopover
    ref="refSPopover"
    v-model="visible"
    trigger="hover"
    append-to-body
    fix-disappear-position
    :delay-render="300"
  >
    <template #reference>
      <div
        ref="reference"
        style="display: inline-block"
      >
        <slot
          name="reference"
          :visible="visible"
        ></slot>
      </div>
    </template>
    <template #default>
      <div class="bsc-cart-item-estimated-popover__body">
        <div class="bsc-cart-item-estimated-popover__header">
          <div class="bsc-cart-item-estimated-popover__header-content">
            <div class="bsc-cart-item-estimated-popover__header-title">
              {{ estimatedPricePopUp.title }}
            </div>
            <div class="bsc-cart-item-estimated-popover__header-price">
              {{ estimatedPricePopUp.estimatedPrice }}
            </div>
          </div>
          <div class="bsc-cart-item-estimated-popover__header-tip">
            {{ estimatedPricePopUp.titleDesc }}
          </div>
        </div>
        <template v-if="visible">
          <template
            v-for="(item, index) in estimatedPricePopUp.subInfo.items"
            :key="index"
          >
            <component
              :is="CompMap[item.type]"
              :data="item.data"
            />
          </template>
        </template>
      </div>
    </template>
  </SPopover>
</template>

<script name="DiscountDetailsPopover" setup lang="ts">
import { ref, watch, onUnmounted } from 'vue'
import DiscountCell from './discount-cell.vue'
import DiscountDropdown from './discount-dropdown.vue'
import { SPopover } from '@shein-aidc/sui-popover/laptop'

const CompMap = {
  2: DiscountCell,
  3: DiscountDropdown,
}

defineProps({
  estimatedPricePopUp: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['opened', 'closed'])

const visible = ref(false)
const refSPopover = ref()

watch(visible, val => {
  if (val) {
    addScrollEventListener()
    emit('opened')
  } else {
    removeScrollEventListener()
    emit('closed')
  }
})

let handleScroll = null
const addScrollEventListener = () => {
  if (handleScroll) return
  // @ts-ignore
  handleScroll = () => {
    refSPopover.value?.close()
  }
  // @ts-ignore
  window.addEventListener('scroll', handleScroll)
}
const removeScrollEventListener = () => {
  // @ts-ignore
  window.removeEventListener('scroll', handleScroll)
  handleScroll = null
}

onUnmounted(() => {
  removeScrollEventListener()
})
</script>

<style lang="less">
.bsc-cart-item-estimated-popover {
  &__body {
    margin-right: -12px;
    padding-right: 12px;
    min-width: 248px;
    width: 260px;
    height: 188px;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
    scrollbar-gutter: stable;
    /* 滚动条整体样式 */
    &::-webkit-scrollbar {
      margin-left: 4px;
      width: 4px; /* 滚动条宽度 */
    }
    /* 滚动条轨道样式 */
    &::-webkit-scrollbar-track {
      background-color: #fff; /* 滚动条轨道背景色 */
    }
    /* 滚动条滑块样式 */
    &::-webkit-scrollbar-thumb {
      background-color: #ababab; /* 滚动条滑块背景色 */
      border-radius: 4px; /* 滚动条滑块圆角 */
    }
  }

  &__header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    line-height: 14px;
    font-size: 16px;
    color: #000;
  }

  &__header-title {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    font-weight: 700;
  }
  &__header-price {
    flex-shrink: 0;
    position: relative;
    max-width: 65%;
    font-weight: 700;
    color: #FA6338;
    text-align: right;
    word-break: break-all;
  }

  &__header-tip {
    margin-top: 4px;
    font-size: 10px;
    color: rgba(#000, .3);
  }
}
</style>
