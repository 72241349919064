import PromotionTag from './promotion-tag.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const usePromotionTag = createHelper('PromotionTag', getProps)

export {
  PromotionTag,
  usePromotionTag,
}
