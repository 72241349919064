<template>
  <div ref="root">
    {{ expanded ? maxText : text }}
  </div>
</template>

<script name="TextEllipsis" setup lang="ts">
import { ref, watch, onMounted, onActivated } from 'vue'
import { windowWidth } from '../utils/windowSize'
import { useIntersectionObserver } from '../hooks/useIntersectionObserver'

const props = defineProps({
  expanded: {
    type: Boolean,
    default: false,
  },
  hasAction: {
    type: Boolean,
    default: false,
  },
  content: {
    type: String,
    default: '',
  },
  expandText: {
    type: String,
    default: '',
  },
  rows: {
    type: Number,
    default: 1,
  },
  maxRows: {
    type: Number,
    default: -1,
  },
  position: {
    type: String,
    default: 'end', // start
  },
  dots: {
    type: String,
    default: '...',
  },
  trigger: {
    type: String,
    default: 'Mounted',
    validator: (trigger: string) => ['Mounted', 'Observer'].includes(trigger),
  },
})

const emit = defineEmits(['update:expanded', 'update:has-action'])

const root = ref()
const text = ref('')
const maxText = ref('')

let needRecalculate = false

const pxToNum = value => {
  if (!value) return 0
  const match = value.match(/^\d*(\.\d*)?/)
  return match ? Number(match[0]) : 0
}

const cloneContainer = () => {
  if (!root.value || !root.value.isConnected) return

  const originStyle = window.getComputedStyle(root.value)
  const container = document.createElement('div')
  const styleNames = Array.prototype.slice.apply(originStyle)

  styleNames.forEach((name) => {
    container.style.setProperty(name, originStyle.getPropertyValue(name))
  })

  container.style.position = 'fixed'
  container.style.zIndex = '-9999'
  container.style.top = '-9999px'
  container.style.height = 'auto'
  container.style.minHeight = 'auto'
  container.style.maxHeight = 'auto'

  container.innerText = props.content
  document.body.appendChild(container)

  return container
}

const calcEllipsisText = (content, container, maxHeight) => {
  const { position, dots } = props
  const end = content.length
  const middle = (0 + end) >> 1
  const actionHTML = props.expandText

  const calcEllipse = () => {
    // calculate the former or later content
    const tail = (left, right) => {
      if (right - left <= 1) {
        if (position === 'end') {
          return content.slice(0, left) + dots
        }
        return dots + content.slice(right, end)
      }

      const middle = Math.round((left + right) / 2)

      // Set the interception location
      if (position === 'end') {
        container.innerText = content.slice(0, middle) + dots
      } else {
        container.innerText = dots + content.slice(middle, end)
      }

      container.innerHTML += actionHTML

      // The height after interception still does not match the rquired height
      if (container.offsetHeight > maxHeight) {
        if (position === 'end') {
          return tail(left, middle)
        }
        return tail(middle, right)
      }

      if (position === 'end') {
        return tail(middle, right)
      }

      return tail(left, middle)
    }

    return tail(0, end)
  }

  const middleTail = (
    leftPart,
    rightPart,
  ) => {
    if (
      leftPart[1] - leftPart[0] <= 1 &&
      rightPart[1] - rightPart[0] <= 1
    ) {
      return (
        content.slice(0, leftPart[0]) +
        dots +
        content.slice(rightPart[1], end)
      )
    }

    const leftMiddle = Math.floor((leftPart[0] + leftPart[1]) / 2)
    const rightMiddle = Math.ceil((rightPart[0] + rightPart[1]) / 2)

    container.innerText =
      props.content.slice(0, leftMiddle) +
      props.dots +
      props.content.slice(rightMiddle, end)
    container.innerHTML += actionHTML

    if (container.offsetHeight >= maxHeight) {
      return middleTail(
        [leftPart[0], leftMiddle],
        [rightMiddle, rightPart[1]],
      )
    }

    return middleTail(
      [leftMiddle, leftPart[1]],
      [rightPart[0], rightMiddle],
    )
  }

  return props.position === 'middle'
    ? middleTail([0, middle], [middle, end])
    : calcEllipse()
}

const calcEllipsised = (rows) => {
  // Calculate the interceptional text
  const container = cloneContainer()

  if (!container) {
    needRecalculate = true
    return ''
  }

  const { paddingBottom, paddingTop, lineHeight } = container.style
  const maxHeight = Math.ceil(
    (Number(rows) + 0.5) * pxToNum(lineHeight) +
      pxToNum(paddingTop) +
      pxToNum(paddingBottom),
  )

  let ellipsisText = props.content
  if (maxHeight < container.offsetHeight) {
    ellipsisText = calcEllipsisText(props.content, container, maxHeight)
  }

  document.body.removeChild(container)

  return ellipsisText
}

const calcRowsEllipsised = () => {
  const ellipsisText = calcEllipsised(props.rows)
  if (ellipsisText !== props.content) {
    emit('update:has-action', true)
  } else {
    emit('update:has-action', false)
  }
  text.value = ellipsisText
}

const calcMaxRowsEllipsised = () => {
  let ellipsisText = props.content
  if (props.maxRows > props.rows) {
    ellipsisText = calcEllipsised(props.maxRows)
  }
  maxText.value = ellipsisText
}

const toggle = () => {
  emit('update:expanded', !props.expanded)
}

watch(
  [windowWidth, () => [props.content, props.rows, props.maxRows, props.position]],
  () => {
    calcRowsEllipsised()
    calcMaxRowsEllipsised()
  },
)

if (props.trigger === 'Observer') {
  useIntersectionObserver(root, ({ isVisible, hasBeenVisible }) => {
    if (!isVisible || hasBeenVisible) return
    calcRowsEllipsised()
    calcMaxRowsEllipsised()
  })
} else {
  onMounted(() => {
    calcRowsEllipsised()
    calcMaxRowsEllipsised()
  })
}

onActivated(() => {
  if (needRecalculate) {
    needRecalculate = false
    calcRowsEllipsised()
    calcMaxRowsEllipsised()
  }
})

defineExpose({
  toggle,
})
</script>
