<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-negative-info"
    :style="styles"
  >
    <template
      v-for="(item, index) in negativeInfos"
      :key="index"
    >
      <GoodsNegativeItem
        :text="item.text"
        :trigger="trigger"
      />
    </template>
  </div>
</template>

<script name="GoodsNegativeInfo" setup lang="ts">
import { computed } from 'vue'
import GoodsNegativeItem from './goods-negative-item.vue'
import type { GoodsNegativeInfo } from '../../../../types/laptop/index.ts'

/**
 * mini车商品负向信息组件
 */

// props
const props = withDefaults(defineProps<GoodsNegativeInfo.Props>(), {
  isShow: true,
  negativeInfos: () => ([]),
  trigger: 'Mounted',
  textColor: '#959595',
  maxWidth: '100%',
})

const styles = computed(() => {
  return {
    '--max-width': props.maxWidth,
    '--text-size': '12px',
    '--text-color': props.textColor,
    '--icon-size': '12px',
    '--line-height': '1.15',
    '--icon-color': props.textColor,
  }
})
</script>

<style lang="less">
.bsc-cart-item-goods-negative-info {
  color: #666;
  line-height: var(--line-height);
}
</style>
