<template>
  <div
    class="bsc-cart-item-easy-coupon"
  >
    <span class="bsc-cart-item-easy-coupon__text">{{ text }}</span>
  </div>
</template>

<script lang="ts">
export default {
  name: 'EasyCoupon',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="less">
@sui_color_discount_dark: #FA6338;
.bsc-cart-item-easy-coupon {
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  border:  1px solid @sui_color_discount_dark;
  border-radius: 1px;
  background: #FFF;
  overflow: hidden;
  mask-image: radial-gradient(circle at 0px 50%, transparent 3px, #fff 3px);
  height: initial;
  padding: 0px 3px 0 6px/* rtl: 0px 3px 0 6px */;
  font-size: 12px;
  color: @sui_color_discount_dark;

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: @sui_color_discount_dark;
    border-radius: 50%;
    top: 50%;
    margin-top: -4px/* rtl: -4px */;
    left: -5px/* rtl: -5px */;
  }

  &__text {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
