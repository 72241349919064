<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-voucher-tag"
    :style="style"
  >
    <div class="bsc-cart-item-voucher-tag__left">
      <div class="bsc-cart-item-voucher-tag__text">
        {{ text }}
      </div>
      <template v-if="tipDisabled">
        <img
          v-if="suffixIcon"
          :src="suffixIcon"
          alt=""
          class="bsc-cart-item-voucher-tag__icon"
        />
      </template>
      <template v-else>
        <SPopover
          show-close-icon
          theme="dark"
          trigger="hover"
          :append-to-body="tipAppendToBody"
          :prop-style="{
            width: '240px'
          }"
        >
          <template #reference>
            <img
              v-if="suffixIcon"
              :src="suffixIcon"
              alt=""
              class="bsc-cart-item-voucher-tag__icon"
            />
          </template>
          <div class="bsc-cart-item-voucher-tag__popover-content">
            {{ tip }}
          </div>
        </SPopover>
      </template>
    </div>
    <div
      v-if="subText"
      class="bsc-cart-item-voucher-tag__right"
    >
      <div class="bsc-cart-item-voucher-tag__line"></div>
      <div class="bsc-cart-item-voucher-tag__subtext">
        {{ subText }}
      </div>
    </div>
  </div>
</template>

<script name="VoucherTag" setup lang="ts">
import { computed } from 'vue'
import { hexToRgb } from '@shein/common-function'
import type { VoucherTag } from '../../../../../types/laptop/index.ts'
import { SPopover } from '@shein-aidc/sui-popover/laptop'


const props = withDefaults(defineProps<VoucherTag.Props>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  tip: '',
  tipDisabled: false,
  tipAppendToBody: true,
  subText: '',
  suffixIcon: '',
  bgColor: '#ffe6e6',
  textColor: '#ff3f3f',
  maxWidth: '100%',
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--bg-color': props.bgColor,
  '--text-color': props.textColor,
  '--separator-color': hexToRgb({ hex: props.textColor, opa: 0.4 }),
  maxWidth: props.maxWidth,
}))
</script>

<style lang="less">
.bsc-cart-item-voucher-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  max-width: 100%;
  height: 20px;
  font-size: 12px;
  color: var(--text-color);
  background: var(--bg-color);
  border-radius: 2px;
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__left {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    gap: 2px;
  }

  &__right {
    flex-shrink: 1;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
  }

  &__text {
    font-weight: 600;
  }

  &__subtext {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__line {
    margin: 0 4px;
    height: 10px;
    border-left: 1px solid var(--separator-color);
  }

  &__icon {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  &__popover-content {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
}
</style>
