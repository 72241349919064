<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-title-icon"
  >
    <img
      class="lazyload"
      :data-src="finallyImgUrl"
      :src="constants.LAZY_IMG"
    />
  </div>
</template>

<script name="GoodsTitleIcon" setup lang="ts">
import { computed } from 'vue'
import { transformImg } from '@shein/common-function'
import { useInjectConstants } from'../../hooks/useConstant'
import type { GoodsTitleIcon } from '../../../../types/laptop/index.ts'
import type { Ref } from 'vue'

/**
 * 商品行标题ICON组件
 */

// hooks
const constants = useInjectConstants<Ref<GoodsTitleIcon.Constant>>()

// props
const props = withDefaults(defineProps<GoodsTitleIcon.Props>(), {
  isShow: false,
  imgUrl: '',
})

// computed
const finallyImgUrl = computed(() => transformImg({ img: props.imgUrl }))
</script>

<style lang="less">
.bsc-cart-item-goods-title-icon {
  margin-right: 4px;
  width: 48px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  > img {
    width: 100%;
  }
}
</style>
