<template>
  <span
    class="bsc_icon_more_down_12px_1"
    :style="styles"
  >
    <svg
      style="width: auto; height: 1em;"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.18203 4.14648L9.88914 4.85359L6.00005 8.74268L2.11096 4.85359L2.81807 4.14648L6.00005 7.32804L9.18203 4.14648Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_more_down_12px_1" setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: '14px',
  },
  color: {
    type: String,
    default: 'inherit',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_more_down_12px_1 {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 36px);
  color: var(--color, '#000');
}
</style>
