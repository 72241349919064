<template>
  <span
    class="bsc_icon_more_down_14px"
    :style="styles"
  >
    <svg
      style="width: auto; height: 1em;"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4177 4.54297L11.8319 5.95718L6.99999 10.7891L2.16809 5.95718L3.5823 4.54297L6.99999 7.96008L10.4177 4.54297Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_more_down_14px" setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: '14px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_more_down_14px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 12px);
  color: var(--color, '#000');
}
</style>
