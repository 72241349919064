<template>
  <span
    class="bsc_icon_closed_36px"
    :style="styles"
  >
    <svg
      style="width: auto; height: 1em;"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7928 8.10049L14.2071 6.68628L25.5208 18L14.2071 29.3137L12.7928 27.8995L22.692 18L12.7928 8.10049Z"
        fill="#1C1C1C" />
    </svg>
  </span>
</template>

<script name="sui_icon_closed_36px" setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: '36px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_closed_36px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 36px);
  color: var(--color, '#000');
  transform: rotate(0) /* rtl: rotate(-180deg)*/;
}
</style>
