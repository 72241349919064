import GoodsDeleteButtonV1 from './goods-delete-button-v1.vue'
import GoodsDeleteButtonV2 from './goods-delete-button-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsDeleteButton = createHelper('GoodsDeleteButton', getProps)

export {
  GoodsDeleteButtonV1,
  GoodsDeleteButtonV2,
  useGoodsDeleteButton,
}
