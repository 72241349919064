import BehaviorLabel from './behavior-label.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useBehaviorLabel = createHelper('BehaviorLabel', getProps)

export {
  BehaviorLabel,
  useBehaviorLabel,
}
