<template>
  <div class="bsc-cart-item-discount-coupon">
    <div class="bsc-cart-item-discount-coupon__left">
      <EasyCoupon
        class="bsc-cart-item-discount-coupon__name"
        :text="item.leftTip"
      />
      <CartCountdown
        v-if="item.endTimeStamp"
        :timestamp="item.endTimeStamp"
        class="bsc-cart-item-discount-coupon__time"
        font-color="#fa6338"
        colon-color="#fa6338"
        font-bg-color="#ffece9"
      />
    </div>
    <div class="bsc-cart-item-discount-coupon__saved">
      <span>{{ item.rightTip }}</span>
    </div>
  </div>
  <div
    v-if="item.questionMark"
    class="bsc-cart-item-discount-coupon__tip"
  >
    {{ item.questionMark }}
  </div>
</template>

<script name="DiscountCoupon" setup lang="ts">
import EasyCoupon from '../easy-coupon.vue'
import CartCountdown from '../cart-countdown.vue'

defineProps({
  item: {
    type: Object,
    required: true,
  },
})
</script>

<style lang="less">
.bsc-cart-item-discount-coupon {
  max-height: 38px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &__left {
    max-width: 100%;
    display: inline-flex;
    align-items: center;
  }

  &__name {
    flex: 1;
    box-sizing: border-box;
    height: 18px!important; /* stylelint-disable-line declaration-no-important */
  }

  &__time {
    overflow: hidden;
    white-space: nowrap;
    max-width: 60px;
    display: inline-block;
    margin-left: 4px;
  }

  &__saved {
    margin-left: 4px;
    display: inline;
    line-height: 20px;
  }

  &__tip {
    margin-top: 4px;
    font-size: 10px;
    color: rgba(#000, .3);
  }
}
</style>
