<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-goods-discount-tag"
  >
    <div class="bsc-cart-item-goods-discount-tag__item">
      {{ unitDiscount }}
    </div>
    <div
      v-if="isShowSaleDiscount"
      class="bsc-cart-item-goods-discount-tag__item"
    >
      {{ saleDiscount }}
    </div>
  </div>
</template>

<script name="GoodsDiscountFlag" setup lang="ts">
import { computed } from 'vue'
import type { GoodsDiscountFlag } from '../../../../types/laptop/index.ts'

/**
 * 商品行商品标签组件
 */

// props
const props = withDefaults(defineProps<GoodsDiscountFlag.Props>(), {
  isShow: false,
  isShowMask: false,
  bgColor: '',
  fontColor: '',
  unitDiscount: '',
  saleDiscount: '',
  isShowSaleDiscount: false,
})

// computed
const styles = computed(() => ({
  '--bg-color': props.bgColor,
  '--font-color': props.fontColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
}))
</script>

<style lang="less">
.bsc-cart-item-goods-discount-tag {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background-color: var(--bg-color);
  color: var(--font-color);
  /*sh:begin*/
  margin-top: 5px;
  /*rw:begin*/
  padding: 0 2px;
  margin-top: 0;
  font-family: Adieu-Bold, Adieu;
  font-weight: bold;
  opacity: var(--mask-opacity);
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 18px;
    white-space: nowrap;
    flex-shrink: 0;
    direction: 'ltr' /*rtl: ignore*/;
    &:nth-child(2) {
      text-decoration: line-through;
      opacity: .8;
    }
  }
}
</style>
