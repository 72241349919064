<template>
  <span
    class="bsc_icon_deals_arrow_16px"
    :style="styles"
  >
    <svg
      style="width: auto; height: 1em;"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.01872 8.96132C5.19805 8.96132 5.35307 8.83619 5.3909 8.6609L6.7722 2.2618C6.9941 1.23382 7.90322 0.5 8.95488 0.5H12.7704C12.9496 0.5 12.9885 0.752273 12.8175 0.806206C11.7733 1.13565 11.0139 2.03962 10.8697 3.12509L10.1164 8.79424C10.1046 8.88273 10.1735 8.96134 10.2627 8.96134L12.713 8.96132C12.8456 8.96132 12.9149 9.11909 12.8251 9.21672L7.15962 15.377C6.99354 15.5576 6.70254 15.5362 6.56455 15.3334L2.55328 9.43726C2.4157 9.23504 2.56054 8.96132 2.80513 8.96132H5.01872Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_deals_arrow_16px" setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: '16px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_deals_arrow_16px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 12px);
  color: var(--color, '#000');
}
</style>
