import GoodsAlert from './goods-alert.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsAlert = createHelper('GoodsAlert', getProps)

export {
  GoodsAlert,
  useGoodsAlert,
}
