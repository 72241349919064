<template>
  <div
    v-if="isShow"
    :class="classes"
    :style="style"
  >
    <span class="bsc-cart-item-more-tag__text">{{ text }}</span>
    <SuperPopover
      v-bind="superPopoverProps"
    >
      <template #default>
        <MoreTagContent
          v-bind="moreTagContentProps"
        />
      </template>
      <template #reference>
        <sui_icon_doubt_16px_1
          :class="classesIcon"
          size="12px"
        />
      </template>
    </SuperPopover>
  </div>
</template>

<script name="MorePromotionTag" setup lang="ts">

import { computed, watch } from 'vue'
import sui_icon_doubt_16px_1 from '../../../components/icon/sui_icon_doubt_16px_1.vue'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'
import SuperPopover from '../../../components/super-popover.vue'
import MoreTagContent from './components/more-tag-content.vue'
import type { MorePromotionTag } from '../../../../../types/laptop/index.ts'

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['expose'])

const props = withDefaults(defineProps<MorePromotionTag.Props>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  popoverTitle: '',
  items: () => ([]),
  disabledIcon: false,
  maxWidth: '100%',
})

const classes = computed(() => ([
  'bsc-cart-item-more-tag',
]))

const classesIcon = computed(() => ([
  'bsc-cart-item-more-tag__icon',
  {
    'bsc-cart-item-more-tag__icon-disabled': props.disabledIcon,
  },
]))

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
}))

const superPopoverProps = computed(() => ({
  disabled: props.disabledIcon,
}))

const moreTagContentProps = computed(() => ({
  content: {
    title: props.popoverTitle,
    list: props.items,
  },
}))

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('MoreTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-more-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  font-size: 12px;
  color: @sui_color_discount_dark;
  background: rgba(250, 240, 238, 0.71);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__icon {
    margin-left: 2px;
    height: 12px;
    cursor: pointer;
    color: @sui_color_discount_dark;
  }

  &__icon-disabled {
    cursor: auto;
  }
}
</style>
