import QuickShipTag from './quick-ship-tag.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useQuickShipTag = createHelper('QuickShipTag', getProps)

export {
  QuickShipTag,
  useQuickShipTag,
}
