import type { GoodsAddWish } from '../../../../types/laptop/index.ts'

/**
 * GoodsAddWish 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }): GoodsAddWish.Props {
  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
  // 附属品
  const isAddBuyItem = data.value.aggregateProductBusiness?.isAddBuy === '1'
  // 失效商品显示到正常分组
  const isSwitchItem = data.value.aggregateProductBusiness?.cartItemStyleType === '2'

  return {
    isShow: !(isGiftItem || isAddBuyItem || !isSwitchItem),
    isShowMask: isNewcomerItem,
    trigger: isNewcomerItem ? 'hover' : 'click',
    placement: isNewcomerItem ? 'top-end' : 'bottom',
    title: isNewcomerItem ? '' : language.value.SHEIN_KEY_PC_17955,
    content: isNewcomerItem ? language.value.SHEIN_KEY_PC_27119 : '',
    delayRender: 300,
    showFooter: !isNewcomerItem,
    btnNo: language.value.SHEIN_KEY_PC_15738,
    btnYes: language.value.SHEIN_KEY_PC_15737,
  }
}
