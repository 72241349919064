<template>
  <span
    class="bsc_icon_more_right_12px_1"
    :style="styles"
  >
    <svg
      style="width: auto; height: 1em;"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.85359 2.11084L8.74268 5.99993L4.85359 9.88901L4.14648 9.18191L7.32804 5.99993L4.14648 2.81795L4.85359 2.11084Z"
        fill="currentColor" />
    </svg>
  </span>
</template>

<script name="sui_icon_more_right_12px_1" setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: '12px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_more_right_12px_1 {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 12px);
  color: var(--color, '#000');
  transform: rotate(0) /* rtl: rotate(-180deg)*/;
}
</style>
