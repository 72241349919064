import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { Laptop } from '../../../types/laptop'

const appConfigs = useAppConfigs()

/**
 * 获取模板中的使用的常量
 */
export const getConstant = function():Laptop.Constant<Laptop.Names> {
  return {
    'LAZY_IMG': 'https://img.ltwebstatic.com/images3_ccc/2024/09/23/59/1727073804dd7ded2c5e63bb011c2c2d48668b3bc3.png',
    'RESOURCE_SDK': appConfigs?.$envs?.RESOURCE_SDK,
  }
}
