import FindSimilarText from './find-similar-text.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useFindSimilarText = createHelper('FindSimilarText', getProps)

export {
  FindSimilarText,
  useFindSimilarText,
}
