import GoodsCustomContent from './goods-custom-content.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsCustomContent = createHelper('GoodsCustomContent', getProps)

export {
  GoodsCustomContent,
  useGoodsCustomContent,
}
