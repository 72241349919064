import type { ReducePriceHint } from '../../../../types/laptop/index.ts'

/**
 * ReducePriceHint 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): ReducePriceHint.Props {
  // 勾选状态不展示降级提醒
  const info = data.value.is_checked == '1' ? null : (data.value.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'priceReduction') || null)

  return {
    isShow: !!info,
    isShowPrefixIcon: !!info?.view?.prefixIcon,
    prefixIcon: info?.view?.prefixIcon,
    hintText: info?.view?.text || '',
    textColor: info?.view?.textColor || '',
  }
}
