import type { GoodsDeleteButton } from '../../../../types/laptop/index.ts'

/**
 * 删除按钮组件 GoodsDeleteButton
 * GoodsDeleteButton 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ config, language }): GoodsDeleteButton.Props {
  if (config.value?.type === 'v1') {
    return {
      title: language.value.SHEIN_KEY_PC_15745,
      buttonYes: language.value.SHEIN_KEY_PC_15737,
      buttonNo: language.value.SHEIN_KEY_PC_15738,
    }
  }
  return {
    title: language.value.SHEIN_KEY_PC_15745,
  }
}
