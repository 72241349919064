import GoodsSeriesFlag from './goods-series-flag.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsSeriesFlag = createHelper('GoodsSeriesFlag', getProps)

export {
  GoodsSeriesFlag,
  useGoodsSeriesFlag,
}
