<template>
  <span
    class="bsc_icon_delete_16px_1"
    :style="styles"
  >
    <svg
      style="width: auto; height: 1em;"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.99996 7V11H5.99996V7H6.99996Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99996 11H8.99996V7H9.99996V11Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3199 1.5H5.68004L4.68004 3.66667H1.66663V4.66667H3.16663V14H12.8333V4.66667H14.3333V3.66667H11.3199L10.3199 1.5ZM10.2185 3.66667L9.67996 2.5H6.31896L5.78107 3.66667H10.2185ZM4.16662 4.66667H11.8333L11.833 12.999H4.16596L4.16662 4.66667Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_delete_16px_1" setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: '16px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_delete_16px_1 {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 12px);
  color: var(--color, '#000');
}
</style>
