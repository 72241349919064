import GoodsImg from './goods-img.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsImg = createHelper('GoodsImg', getProps)

export {
  GoodsImg,
  useGoodsImg,
}
