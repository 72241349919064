import type { GoodsStore } from '../../../../types/laptop/index.ts'

/**
 * GoodsStore 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsStore.Props {
  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
  // 附属品
  const isAddBuyItem = data.value.aggregateProductBusiness?.isAddBuy === '1'
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  return {
    isShow: data.value.isShowStoreTitle === '1',
    isShowMask: isInvalidItem || (isGiftItem && isNotChecked) || isAddBuyItem || isNewcomerItem,
    isClick: !!data.value.storeRouting,
    storeTitle: data.value.store_title,
    storeLogo: data.value.store_logo,
    storeIconSize: '16px',
    textFontSize: '13px',
    textFontWeight: 'normal',
    maxWidth: '100%',
  }
}
