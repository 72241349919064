<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-find-similar-icon"
    @click="handleClick"
  >
    <sui_icon_search_18px_1 size="16px" />
  </div>
</template>

<script name="FindSimilarIcon" setup lang="ts">
import { watch, computed } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import sui_icon_search_18px_1 from '../../components/icon/sui_icon_search_18px_1.vue'
import type { FindSimilarIcon } from '../../../../types/laptop/index.ts'

/**
 * 商品行找同款文案组件
 */

// hooks
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['click', 'report', 'expose'])

// props
const props = withDefaults(defineProps<FindSimilarIcon.Props>(), {
  isShow: false,
  isShowMask: false,
  isClick: false,
})

// data
// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})

// events
const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}

/**
 * 组件曝光
 */
const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('FindSimilarIcon', callback)
    }
  }, {
    immediate: true,
  })
}

handleExpose()
</script>

<style lang="less">
.bsc-cart-item-find-similar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: var(--mask-opacity);
  &:hover {
    background: #f6f6f6;
  }
}
</style>
