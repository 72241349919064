import FindSimilarIcon from './find-similar-icon.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useFindSimilarIcon = createHelper('FindSimilarIcon', getProps)

export {
  FindSimilarIcon,
  useFindSimilarIcon,
}
