import GoodsRepickButton from './goods-repick-button.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsRepickButton = createHelper('GoodsRepickButton', getProps)

export {
  GoodsRepickButton,
  useGoodsRepickButton,
}
