import AddMore from './add-more.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useAddMore = createHelper('AddMore', getProps)

export {
  AddMore,
  useAddMore,
}
