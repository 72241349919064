import IncentiveReducePrice from './incentive-reduce-price.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useIncentiveReducePrice = createHelper('IncentiveReducePrice', getProps)

export {
  IncentiveReducePrice,
  useIncentiveReducePrice,
}
