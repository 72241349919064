import MorePromotionTag from './more-promotion-tag.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useMorePromotionTag = createHelper('MorePromotionTag', getProps)

export {
  MorePromotionTag,
  useMorePromotionTag,
}
