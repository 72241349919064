<template>
  <div
    :style="styles"
    :class="classes"
    @click="handleClick"
  >
    <div class="bsc-cart-item-goods-img__content">
      <img
        v-if="!isLazyLoad"
        :src="finallyImgUrl"
      />
      <img
        v-else
        class="lazyload"
        :data-src="finallyImgUrl"
        :src="constants.LAZY_IMG"
        :data-design-width="designWidth"
        :data-exp="cutSizeStr"
      />
    </div>
    <div class="bsc-cart-item-goods-img__slot-left">
      <slot name="left"></slot>
    </div>
    <div class="bsc-cart-item-goods-img__slot-right">
      <slot name="right"></slot>
    </div>
    <div class="bsc-cart-item-goods-img__slot-bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script name="GoodsImg" setup lang="ts">
import { transfromAndCutImg } from '../../utils/resource'
import { computed, onMounted } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import { useInjectConstants } from'../../hooks/useConstant'
import type { GoodsImg } from '../../../../types/laptop/index.ts'
import type { Ref } from 'vue'

/**
 * 商品行图片组件
 */

// hooks
const constants = useInjectConstants<Ref<GoodsImg.Constant>>()
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['click', 'report', 'expose'])

// props
const props = withDefaults(defineProps<GoodsImg.Props>(), {
  width: '100%',
  height: '100%',
  imgUrl: '',
  isShowMask: false,
  isLazyLoad: true,
  isCutImg: false,
  isClick: true,
})

// data
const designWidth = 1440
const cutSize = computed(() => ({
  cutTo: 240,
  max: 360,
}))
const cutSizeStr = computed(() => (Object.entries(cutSize.value).map(([key, value]) => `${key}=${value}`).join('#')))

// computed
const styles = computed(() => {
  return {
    '--width': props.width,
    '--height': props.height,
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})
const classes = computed(() => ({
  'bsc-cart-item-goods-img__wrap': true,
  'bsc-cart-item-goods-img_hover': props.isClick,
}))
const finallyImgUrl = computed(() => {
  if (props.isCutImg) {
    const { deviceData = '', isSupportWeb = '', isSupprotCut = false } = constants.value?.RESOURCE_SDK || {}
    const cutData = {
      deviceData,
      isSupportWebp: Boolean(isSupportWeb),
      isSupprotCut,
      imgUrl: props.imgUrl,
      designWidth: Number(designWidth),
      exp: cutSize.value,
    }
    return transfromAndCutImg(cutData)
  }
  return props.imgUrl
})

// events
const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('GoodsImg', callback)
}

onMounted(handleExpose)
</script>

<style lang="less">
.bsc-cart-item-goods-img {
  &__wrap {
    position: relative;
    width: var(--width);
    height: var(--height);
    overflow: hidden;
    &:hover.bsc-cart-item-goods-img_hover {
      cursor: pointer;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    height: 100%;
    img {
      width: 100%;
    }
    opacity: var(--mask-opacity);
  }
  &__slot-left {
    position: absolute;
    direction: ltr /*rtl:ignore*/;
    left: 0;
    top: 0;
    opacity: var(--mask-opacity);
  }
  &__slot-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    opacity: var(--mask-opacity);
  }
  &__slot-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
