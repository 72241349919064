<template>
  <span :style="styles">
    <svg
      style="width: auto; height: 1em;"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.48481 2.99319C5.94836 1.61989 3.58835 1.67075 2.1129 3.14578C0.584759 4.67392 0.673978 6.84724 2.1129 8.67908C2.34182 8.97051 2.6469 9.31821 2.99812 9.69691L3.43981 10.1646L3.91426 10.6523L4.5178 11.2576L4.87913 11.6137L5.54562 12.261L6.62675 13.2902L8 14.5662L9.37325 13.2902L10.4544 12.261L11.4822 11.2576L12.0857 10.6523L12.5602 10.1646C12.6369 10.0847 12.7122 10.0057 12.7858 9.92764L13.2068 9.47361C13.4721 9.18138 13.704 8.91222 13.8871 8.67908C15.326 6.84724 15.4152 4.67392 13.8871 3.14578C12.359 1.61807 9.88194 1.61807 8.3538 3.14578L8 3.49957L7.6462 3.14578L7.48481 2.99319ZM2.95972 3.72197C4.10394 2.71749 5.84706 2.76116 6.9392 3.85298L8 4.91379L9.0609 3.85288L9.20071 3.72188C10.3448 2.71749 12.088 2.76116 13.1801 3.85298C14.2951 4.96802 14.2766 6.56431 13.1007 8.06136L12.8813 8.32967C12.8409 8.37756 12.7985 8.42702 12.7542 8.47799L12.4662 8.80179L12.135 9.16021L11.7626 9.55135L11.1311 10.1952L10.4189 10.9015L9.9023 11.4042C9.37809 11.9109 8.83345 12.4274 8.28973 12.9355L8 13.205L7.167 12.4252C6.62495 11.9134 6.0888 11.3991 5.5791 10.8995L5.21976 10.5453L4.8665 10.1928L4.43708 9.75719L4.04549 9.35109L3.69383 8.97649L3.38418 8.63536L3.11864 8.32966L2.8993 8.06136C1.72335 6.56431 1.70487 4.96802 2.81991 3.85298L2.95972 3.72197Z"
        fill="currentColor" />
    </svg>
  </span>
</template>

<script name="sui_icon_save_16px_1" setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: '14px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  'vertical-align': 'middle',
  display: 'inline-block',
  'line-height': 0,
  'font-size': props.size,
  color: props.color,
})) as any
</script>
