import type { GoodsQty } from '../../../../types/laptop/index.ts'

/**
 * 数量编辑器组件 GoodsQty
 * GoodsQty 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }): GoodsQty.Props {
  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
  // 附属品
  const isAddBuyItem = data.value.aggregateProductBusiness?.isAddBuy === '1'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  // 最大可购买数量 Math.min(单品限购数量, 实时库存)
  const maxCanPurchaseQuantity = data.value.aggregateProductBusiness?.maxCanPurchaseQuantity
  let max = 99
  if (maxCanPurchaseQuantity) {
    max = Math.min(maxCanPurchaseQuantity, 99)
  }
  // 勾选数量超过库存导致失效
  const isSoldoutByInventory = data.value.aggregateProductBusiness?.isSoldOutByInventory == '1'
  /**
   * 是否显示
   * 1. 不是赠品并且不是失效商品会显示
   * 2. 因为勾选数量超过库存导致失效的商品会显示
   */
  const isShow = (!isGiftItem && !isInvalidItem) || isSoldoutByInventory


  let isShowMask = false
  let isClick = true
  /**
   * 新人专享价商品 || 附赠品 需要展示蒙层且不可点击 || 失效商品且不是因为库存导致的失效商品(mini车场景失效商品会展示数量编辑器)
   */
  if (isNewcomerItem || isAddBuyItem || isGiftItem || (isInvalidItem && !isSoldoutByInventory)) {
    isShowMask = true
    isClick = false
  }

  return {
    isShow,
    isShowMask,
    isClick,
    isSoldoutByInventory,
    value: data.value.quantity,
    max,
    inputTips: language.value.SHEIN_KEY_PC_28787,
  }
}
