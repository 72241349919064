<template>
  <span :style="styles">
    <svg
      style="width: auto; height: 1em;"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.00736 4.00736C6.3505 1.66421 10.1495 1.66421 12.4926 4.00736C14.7167 6.23144 14.8297 9.76718 12.8317 12.1246L16.0282 15.3211L15.3211 16.0282L12.1246 12.8317C9.76718 14.8297 6.23144 14.7167 4.00736 12.4926C1.66421 10.1495 1.66421 6.3505 4.00736 4.00736ZM11.7855 4.71447C9.83291 2.76184 6.66709 2.76184 4.71447 4.71447C2.76184 6.66709 2.76184 9.83291 4.71447 11.7855C6.66709 13.7382 9.83291 13.7382 11.7855 11.7855C13.7382 9.83291 13.7382 6.66709 11.7855 4.71447Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_search_18px_1" setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: '14px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  'vertical-align': 'middle',
  display: 'inline-block',
  'line-height': 0,
  'font-size': props.size,
  color: props.color,
})) as any
</script>
