import type { GoodsImg } from '../../../../types/laptop/index.ts'
import { isCustomizationProduct } from '../../utils/isCustomizationProduct'

/**
 * GoodsImg 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsImg.Props {
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  return {
    width: '100%',
    height: '100%',
    imgUrl: data.value.product?.goods_img || data.value.product?.goods_thumb || '',
    isShowMask: (isGiftItem && isNotChecked) || isInvalidItem,
    isLazyLoad: true,
    isCutImg: !isCustomizationProduct(data.value),
    isClick: true,
  }
}
