import EstimatedPrice from './estimated-price.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useEstimatedPrice = createHelper('EstimatedPrice', getProps)

export {
  EstimatedPrice,
  useEstimatedPrice,
}
