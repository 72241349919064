<template>
  <span
    class="bsc_icon_doubt_16px_1"
    :style="styles"
  >
    <svg
      style="width: auto; height: 1em;"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.492 4.91295C9.80094 3.71593 8.27032 3.3058 7.07331 3.9969C6.2136 4.49325 5.75979 5.42265 5.82832 6.35048H6.83315C6.76085 5.7692 7.03413 5.17422 7.57331 4.86293C8.29203 4.44797 9.21106 4.69422 9.62601 5.41295C10.041 6.13167 9.79472 7.0507 9.07599 7.46565L8.90193 7.57418C8.11126 8.10533 7.62668 8.97561 7.58203 9.91917L7.58201 9.97932H8.58147V9.9428L8.59625 9.78096C8.67571 9.19117 9.03027 8.64675 9.57599 8.33168L9.71584 8.24479C10.8041 7.51867 11.1555 6.06208 10.492 4.91295Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.58174 10.9793H8.58147V11.9793H7.58147L7.58174 10.9793Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1ZM8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_doubt_16px_1" setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: '16px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_doubt_16px_1 {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 16px);
  color: var(--color, '#000');
}
</style>
