<template>
  <div
    class="bsc-cart-item-promotion-icon"
  >
    <!-- 有付费会员标签，不展示其他标签 -->
    <sui_icon_club_logo_fill_14px
      v-if="isShowPremiumMemberIcon"
      :color="premiumMemberIconColor"
      size="16px"
    />
    <template v-else>
      <sui_icon_time_16px
        v-if="isShowTimeIcon"
        :color="timeIconColor"
        size="16px"
      />
      <sui_icon_phone_16px
        v-else-if="isShowAppOnlyIcon"
        :color="appOnlyIconColor"
        size="16px"
      />
      <sui_icon_deals_arrow_16px
        v-else-if="isShowBrandSaleIcon"
        :color="brandSaleIconColor"
        size="16px"
      />
      <sui_icon_flashsale_16px
        v-else-if="isShowFlashSaleIcon"
        :color="flashSaleIconColor"
        size="16px"
      />
    </template>
  </div>
</template>

<script setup>
import sui_icon_club_logo_fill_14px from '../../../components/icon/sui_icon_club_logo_fill_14px.vue'
import sui_icon_time_16px from '../../../components/icon/sui_icon_time_16px.vue'
import sui_icon_phone_16px from '../../../components/icon/sui_icon_phone_16px.vue'
import sui_icon_deals_arrow_16px from '../../../components/icon/sui_icon_deals_arrow_16px.vue'
import sui_icon_flashsale_16px from '../../../components/icon/sui_icon_flashsale_16px.vue'

defineProps({
  /**
   * 是否展示付费会员图标
   */
  isShowPremiumMemberIcon: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示限时促销图标
   */
  isShowTimeIcon: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示APP专享图标
   */
  isShowAppOnlyIcon: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示品牌特卖图标
   */
  isShowBrandSaleIcon: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示闪购图标
   */
  isShowFlashSaleIcon: {
    type: Boolean,
    default: false,
  },
  /**
   * 付费会员图标颜色
   */
  premiumMemberIconColor: {
    type: String,
    default: '',
  },
  /**
   * 限时促销图标颜色
   */
  timeIconColor: {
    type: String,
    default: '',
  },
  /**
   * APP专享图标颜色
   */
  appOnlyIconColor: {
    type: String,
    default: '',
  },
  /**
   * 品牌特卖图标颜色
   */
  brandSaleIconColor: {
    type: String,
    default: '',
  },
  /**
   * 闪购图标颜色
   */
  flashSaleIconColor: {
    type: String,
    default: '',
  },
})
</script>
