<template>
  <div class="bsc-cart-item-ball-loading">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<style lang="less">
.bsc-cart-item-ball-loading {
  font-size: 0;
  &>div {
    background-color: #000000;
    width: 8px;
    height: 8px /* rw: 18px */;
    border-radius: 50% /* rw: none */;
    display: inline-block;
    margin: 0 2px;
    vertical-align: middle;
    animation: ball-pulse 1s ease infinite /* rw: line-scale 1s ease infinite */;
    &:nth-child(1) {
      animation-delay: -200ms;
    }
    &:nth-child(2) {
      animation-delay: -100ms;
    }
    &:nth-child(3) {
      animation-delay: 0ms;
    }
  }
}

@keyframes ball-pulse {
    0%,
    60%,
    100% {
        transform: scale(1);
        opacity: 1;
    }
    30% {
        transform: scale(.01);
        opacity: .1;
    }
}

@keyframes line-scale {
    0%,
    40%,
    100% {
        transform: scaleY(.5);
    }
    20% {
        transform: scaleY(1);
    }
}
</style>
