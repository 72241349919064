<template>
  <div
    v-if="isShow"
    :class="classes"
    :style="style"
  >
    <div class="label-text">{{ text }}</div>
    <template v-if="suffixText">
      <div class="divide-line">|</div>
      <div class="extend-text">{{ suffixText }}</div>
    </template>
  </div>
</template>
<script setup name="SFSTag" lang="ts">
import { computed, defineProps } from 'vue'
import { hexToRgb } from '@shein/common-function'
import type { SFSTag } from '../../../../../types/laptop/index.ts'

const props = withDefaults(defineProps<SFSTag.Props>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  suffixText: '',
  isShowNewStyle: false,
  bgColor: '',
  textColor: '',
  maxWidth: '',
})

const classes = computed(() => {
  return [
    'bsc-cart-item-sfs-tag',
  ]
})

const style = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
    maxWidth: props.maxWidth,
    '--bg-color': props.bgColor,
    '--text-color': props.textColor,
    '--separator-color': hexToRgb({ hex: props.textColor, opa: 0.5 }),
  }
})

</script>
<style lang="less">
.bsc-cart-item-sfs-tag {
  display: flex;
  align-items: center;
  gap: 2px;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
  color: var(--text-color, rgba(25, 128, 85, 1));
  background: var(--bg-color, #ECFCF3);
  padding: 1px 3px;
  border-radius: 2px;
  font-size: 12px;
  opacity: var(--mask-opacity, 1);
  .label-text {
    font-weight: 590;
    flex: 1 0 auto;
  }
  .divide-line {
    color: var(--separator-color);
    margin: 0 2px;
  }
  .extend-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
