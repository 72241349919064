import type { GoodsBelt } from '../../../../types/laptop/index.ts'
import { hexToRgb } from '@shein/common-function'

/**
 * GoodsBelt 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsBelt.Props {
  const bgColor = data.value.aggregateProductBusiness?.productImgBelt?.bgColor || '#000000'
  const bgColorAlpha = data.value.aggregateProductBusiness?.productImgBelt?.bgColorAlpha || '0.6'
  const fontColor = data.value.aggregateProductBusiness?.productImgBelt?.textColor || '#FFFFFF'
  const beltText = data.value.aggregateProductBusiness?.productImgBelt?.text || ''
  return {
    isShow: !!beltText,
    isShowMask: (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1') && data.value.is_checked === '0',
    bgColor: hexToRgb({ hex: bgColor, opa: bgColorAlpha }),
    fontColor,
    beltText: beltText,
    size: 'NORMAL',
  }
}
