<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-reduce-price-hint"
    :style="styles"
  >
    <img
      v-if="isShowPrefixIcon && prefixIcon"
      :src="prefixIcon"
      alt=""
      class="bsc-cart-item-reduce-price-hint__icon"
    />
    <div
      class="bsc-cart-item-reduce-price-hint__text"
      v-html="hintText"
    >
    </div>
  </div>
</template>

<script name="ReducePriceHint" setup lang="ts">
import { computed } from 'vue'
import type { ReducePriceHint } from '../../../../types/laptop/index.ts'

/**
 * 商品行降价提示组件
 */

// props
const props = withDefaults(defineProps<ReducePriceHint.Props>(), {
  isShow: false,
  isShowPrefixIcon: false,
  prefixIcon: '',
  hintText: '',
  textColor: '#C44A01',
})

const styles = computed(() => ({
  '--text-color': props.textColor,
}))
</script>

<style lang="less">
.bsc-cart-item-reduce-price-hint {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: var(--text-color);
  .bsc-cart-item-reduce-price-hint__icon {
    width: 12px;
    height: 12px;
  }
  .bsc-cart-item-reduce-price-hint__text {
    strong {
      font-weight: bold;
    }
  }
}
</style>
