import GoodsQty from './goods-qty.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsGoodsQty = createHelper('GoodsQty', getProps)

export {
  GoodsQty,
  useGoodsGoodsQty,
}
