import type { GoodsDiscountFlag } from '../../../../types/laptop/index.ts'

/**
 * GoodsDiscountFlag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsDiscountFlag.Props {
  const apb = data.value.aggregateProductBusiness || {}
  return {
    isShow: apb.discountSubscript && !apb.discountSubscript?.style,
    isShowMask: ((apb.isPresent === '1' || apb.isPayMemberGift === '1') && data.value.is_checked === '0') || data.value.isInvalid === '1',
    bgColor: apb.discountSubscript?.backgroundColor,
    fontColor: apb.discountSubscript?.textColor,
    unitDiscount: apb.discountSubscript?.value ?? '',
    saleDiscount: apb.discountSubscript?.additionalValue ?? '',
    isShowSaleDiscount: !!apb.discountSubscript?.additionalValue,
  }
}
