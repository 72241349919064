import GoodsNegativeInfo from './goods-negative-info.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsNegativeInfo = createHelper('GoodsNegativeInfo', getProps)

export {
  GoodsNegativeInfo,
  useGoodsNegativeInfo,
}
