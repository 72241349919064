import { Laptop } from '../../../types/laptop'

const LANG = {
  AddMore: [
    'SHEIN_KEY_PC_31563',
  ],
  GoodsAddWish: [
    'SHEIN_KEY_PC_17955',
    'SHEIN_KEY_PC_27119',
    'SHEIN_KEY_PC_15738',
    'SHEIN_KEY_PC_15737',
  ],
  GoodsAlert: [
    'SHEIN_KEY_PC_27125',
  ],
  GoodsCustomContent: [
    'SHEIN_KEY_PC_27387',
  ],
  GoodsDeleteButton: [
    'SHEIN_KEY_PC_15745',
    'SHEIN_KEY_PC_15737',
    'SHEIN_KEY_PC_15738',
  ],
  GoodsQty: [
    'SHEIN_KEY_PC_28787',
  ],
  GoodsSaleAttr: [
    'SHEIN_KEY_PC_16381',
  ],
  IncentiveReducePrice: [
    'SHEIN_KEY_PC_31421',
  ],
}


/**
 * 获取语言映射
 */
export const getLangMap = (names:Laptop.Names[] = []) => {
  if (names.length === 0) {
    return Object.values(LANG).flat().reduce((acc, key) => {
      acc[key] = key
      return acc
    }, {})
  }
  return names.reduce((acc, key) => {
    if (LANG[key]) {
      LANG[key].forEach((item) => {
        acc[item] = item
      })
    }
    return acc
  }, {})
}
