import ReducePriceHint from './reduce-price-hint.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useReducePriceHint = createHelper('ReducePriceHint', getProps)

export {
  ReducePriceHint,
  useReducePriceHint,
}
