import GoodsTitle from './goods-title.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsTitle = createHelper('GoodsTitle', getProps)

export {
  GoodsTitle,
  useGoodsTitle,
}
