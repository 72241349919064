<template>
  <Transition :name="transitionName">
    <div
      v-show="show"
      ref="selectRef"
      tabindex="-1"
      :class="[
        'bsc-cart-item-goods-qty-select',
        `bsc-cart-item-goods-qty-select_${props.placement}`
      ]"
      @blur.stop="handleBlur"
    >
      <slot></slot>
    </div>
  </Transition>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  placement: {
    type: String,
    default: 'bottom',
  },
})
const emit = defineEmits(['update:show'])
const transitionNames = {
  top: 'goods-qty-animation__selectmenu_top',
  bottom: 'goods-qty-animation__selectmenu',
}
const transitionName = computed(() => transitionNames[props.placement])

const selectRef = ref(null)
const focus = () => {
  selectRef.value?.focus()
}
const handleBlur = () => {
  emit('update:show', false)
}

defineExpose({
  focus,
})
</script>

<style lang="less">
.bsc-cart-item-goods-qty-select {
  position: absolute;
  right: 0;
  width: 64px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
  background-color: #fff;
  z-index: 2;
  will-change: transform;
  &::before {
    content: '';
    position: absolute;
    right: var(--select-arrow-right);
    width: 16px;
    height: 8px;
    background-color: inherit;
  }
  &_bottom {
    top: calc(100% + 10px);
    &::before {
      top: -8px;
      clip-path: polygon(50% 0, 100% 100%, 0 100% );
    }
  }
  &_top {
    bottom: calc(100% + 10px);
    &::before {
      bottom: -8px;
      clip-path: polygon(0 0, 100% 0, 50% 100% );
    }
  }
}

/* stylelint-disable selector-class-pattern */
.goods-qty-animation__selectmenu_top-enter,
.goods-qty-animation__selectmenu_top-enter-active {
  animation: sui-selectmenu-in-top 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.goods-qty-animation__selectmenu_top-leave,
.goods-qty-animation__selectmenu_top-leave-active {
  animation: sui-selectmenu-out-top 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes sui-selectmenu-in-top {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes sui-selectmenu-out-top {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

.goods-qty-animation__selectmenu-enter,
.goods-qty-animation__selectmenu-enter-active {
  animation: sui-selectmenu-in 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.goods-qty-animation__selectmenu-leave,
.goods-qty-animation__selectmenu-leave-active {
  animation: sui-selectmenu-out 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes sui-selectmenu-in {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes sui-selectmenu-out {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

</style>
