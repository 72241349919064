<template>
  <div class="bsc-cart-item-discount-dropdown">
    <div
      :class="[
        'bsc-cart-item-discount-dropdown__bar',
        hasList && 'pointer',
      ]"
      @click="onClick"
    >
      <div class="bsc-cart-item-discount-dropdown__bar-content">
        <div class="bsc-cart-item-discount-dropdown__left-tip">
          {{ data.leftKeyTip }}:
          <CartCountdown
            v-if="data.endTimeStamp"
            :timestamp="data.endTimeStamp"
            font-color="#fa6338"
            colon-color="#fa6338"
            font-bg-color="#ffece9"
            style="margin: 0 6px"
          />
        </div>
        <div class="bsc-cart-item-discount-dropdown__right-price">
          <img
            v-if="data.rightValueIconUrl"
            :style="{
              marginRight: '4px',
              width: '14px',
              height: '14px',
            }"
            :src="data.rightValueIconUrl"
          />
          <span :style="rightValuePriceStyle">
            -{{ data.rightValuePrice.amountWithSymbol }}
          </span>
          <Icon
            v-if="hasList"
            name="sui_icon_more_down_12px_1"
            size="12px"
            :class="[
              'bsc-cart-item-discount-dropdown__icon-more',
              isOpen && 'active',
            ]"
          />
        </div>
      </div>
      <div
        v-if="data.questionMark"
        class="bsc-cart-item-discount-dropdown__bar-tip"
      >
        {{ data.questionMark }}
      </div>
    </div>
    <template v-if="isOpen && data.twoLevelData">
      <div
        v-for="(item, index) in data.twoLevelData"
        :key="index"
        class="bsc-cart-item-discount-dropdown__item"
      >
        <slot
          name="item-top"
          :data="item"
        >
        </slot>
        <template v-if="item.type === '1'">
          <div class="bsc-cart-item-discount-dropdown__promotion">
            <DiscountPromotion :item="item" />
          </div>
        </template>
        <template v-else-if="item.type === '2'">
          <div class="bsc-cart-item-discount-dropdown__coupon">
            <DiscountCoupon :item="item" />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script name="DiscountDropdown" setup lang="ts">
import { ref, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import CartCountdown from '../cart-countdown.vue'
import DiscountPromotion from './discount-promotion.vue'
import DiscountCoupon from './discount-coupon.vue'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['fold'])

const rightValuePriceStyle = computed(() => ({
  color: props.data.rightValueFontColor,
}))
const hasList = computed(() => !!props.data.twoLevelData?.length)

const isOpen = ref(false)
const onClick = () => {
  isOpen.value = !isOpen.value
  emit('fold', isOpen.value, props.data)
}
</script>

<style lang="less">
.bsc-cart-item-discount-dropdown {
  &__bar {
    margin: 8px 0;

    &.pointer {
      cursor: pointer;
    }
  }

  &__bar-content {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    line-height: normal;
  }

  &__bar-tip {
    margin-top: 4px;
    font-size: 10px;
    color: rgba(#000, .3);
  }

  &__left-tip {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  &__right-price {
    flex-shrink: 0;
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 65%;
    font-weight: 700;
    color: #FA6338;
    text-align: right;
    word-break: break-all;
  }

  &__icon-more {
    position: absolute;
    top: 50%;
    right: -12px;
    margin-top: -6px;
    color: @sui_color_gray_light1 !important; /* stylelint-disable-line declaration-no-important */

    &.active {
      transform: rotateX(180deg);
    }
  }

  &__item {
    padding: 8px;
    font-size: 12px;
    color: #767676;
    background: #F6F6F6;
    &:first-child {
      box-shadow: 0 .5px 0 0 #E5E5E5 inset;
    }
  }
}
</style>
