<template>
  <span
    class="bsc_icon_reported_12px_1"
    :style="styles"
  >
    <svg
      style="width: auto; height: 1em;"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5 8V9H5.5V8H6.5Z"
        fill="currentColor" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5 7H5.5V3H6.5V7Z"
        fill="currentColor" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6ZM2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6Z"
        fill="currentColor" />
    </svg>
  </span>
</template>

<script name="sui_icon_reported_12px_1" setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: '16px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_reported_12px_1 {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 12px);
  color: var(--color, '#000');
}
</style>
