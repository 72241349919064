import GoodsBelt from './goods-belt.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsBelt = createHelper('GoodsBelt', getProps)

export {
  GoodsBelt,
  useGoodsBelt,
}
