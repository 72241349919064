import VoucherTag from './voucher-tag.vue'
import { getProps, getEvents } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useVoucherTag = createHelper('VoucherTag', getProps, getEvents)

export {
  VoucherTag,
  useVoucherTag,
}
