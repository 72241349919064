/**
 * 获取 promotionTag 上展示的倒计时
 */
export const getTimestampFormProductTags = (productTags) => {
  let timestamp = ''
  if (productTags?.length) {
    let tag = productTags.find(tag => tag.type === 'insurePrice')
    if (!tag) tag = productTags.find(tag => tag.type === 'payMemberGift')
    if (!tag) tag = productTags.find(tag => tag.type === 'promotion')
    if (tag) {
      const data = tag.data?.[tag.type]
      timestamp = data?.endTimeStamp || ''
    }
  }
  return timestamp
}
