import GoodsTitleIcon from './goods-title-icon.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsTitleIcon = createHelper('GoodsTitleIcon', getProps)

export {
  GoodsTitleIcon,
  useGoodsTitleIcon,
}
