
<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-alert"
    v-html="text"
  >
  </div>
</template>

<script name="GoodsAlert" setup lang="ts">
import { computed } from 'vue'
import type { GoodsAlert } from '../../../../types/laptop/index.ts'

/**
 * 商品行提示组件
 */

// props
const props = withDefaults(defineProps<GoodsAlert.Props>(), {
  isShow: false,
  newUserTips: '',
  newUserStockOutTips: '',
  payMemberGiftStockOutTips: '',
  reselectTip: '',
  changeMallTip: '',
})

const text = computed(() => {
  return props.newUserTips || props.newUserStockOutTips || props.payMemberGiftStockOutTips || props.changeMallTip || props.reselectTip
})
</script>

<style lang="less">
.bsc-cart-item-goods-alert {
  font-size: 13px;
  color: #bf4123;
}
</style>
