<template>
  <span
    v-if="isShow"
  >
    <SPopover
      v-model="isOpen"
      :trigger="trigger"
      :placemen="placement"
      :title="title"
      :content="content"
      :delay-render="delayRender"
      :show-footer="showFooter"
      @button-click="handleButtonClick"
    >
      <template #buttonYes>
        {{ btnYes }}
      </template>
      <template #buttonNo>
        {{ btnNo }}
      </template>
      <template #reference>
        <div
          :styles="styles"
          class="bsc-cart-item-goods-add-wish"
          @click="handleClick"
        >
          <sui_icon_save_16px_1 size="16px" />
        </div>
      </template>
    </SPopover>
  </span>
</template>

<script name="GoodsAddWish" setup lang="ts">
import { ref, computed } from 'vue'
import { SPopover } from '@shein-aidc/sui-popover/laptop'
import sui_icon_save_16px_1 from '../../components/icon/sui_icon_save_16px_1.vue'
import type { GoodsAddWish } from '../../../../types/laptop/index.ts'

/**
 * 商品行添加心愿组件
 */


// events
const emit = defineEmits(['click', 'report', 'buttonReport', 'buttonClick'])

// props
const props = withDefaults(defineProps<GoodsAddWish.Props>(), {
  isShow: false,
  isShowMask: false,
  trigger: 'click',
  placement: 'bottom',
  title: '',
  content: '',
  delayRender: 300,
  showFooter: true,
  btnYes: '',
  btnNo: '',
})

// data
const isOpen = ref(false)
// computed
const styles = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
}))
// events
const handleClick = () => {
  if (isOpen.value) return
  emit('report')
  emit('click')
}
const handleButtonClick = (e) => {
  isOpen.value = false
  emit('buttonReport', e)
  emit('buttonClick', e)
}
</script>

<style lang="less">
.bsc-cart-item-goods-add-wish {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: var(--mask-opacity);
  &:hover {
    background: #f6f6f6;
  }
}
</style>
