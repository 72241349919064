<template>
  <div
    v-if="isShow"
    :class="[
      'bsc-cart-item-goods-qty',
      {
        'bsc-cart-item-goods-qty_mt': isShowInputTips && autoAlignByShowInputTips,
      },
    ]"
    :style="styles"
  >
    <div
      ref="qtyContentRef"
      :class="[
        'bsc-cart-item-goods-qty__content',
        {
          'bsc-cart-item-goods-qty__content_mask': isShowMask,
        },
      ]"
    >
      <div
        class="bsc-cart-item-goods-qty__input-wrap"
        @click="clickInputWrapper"
      >
        <label class="bsc-cart-item-goods-qty__label">
          Qty:
        </label>
        <input
          ref="inputRef"
          v-model="quantity"
          :placeholder="value"
          :class="[
            'bsc-cart-item-goods-qty__input',
            {
              'bsc-cart-item-goods-qty__input_error': isInputError
            }
          ]"
          autocomplete="off"
          type="text"
          @blur.stop="handleInputBlur"
          @input="handleInput"
          @keyup.enter="handleInputEnterKeyup"
        />
      </div>
      <sui_icon_more_down_14px
        :class="[
          'bsc-cart-item-goods-qty__suffix',
          {
            'bsc-cart-item-goods-qty__suffix_actived': isShowSelect
          }
        ]"
        @mousedown.prevent
        @click="clickSuffix"
      />
    </div>
    <GoodsQtySelect
      ref="selectRef"
      v-model:show="isShowSelect"
      :placement="placement"
    >
      <GoodsQtyOption
        v-for="option in options"
        :key="option.value"
        :label="option.label"
        :value="option.value"
        :disabled="option.disabled"
        :select-value="value"
        :max-option-number="maxOptionNumber"
        @click="clickOption(option)"
      />
    </GoodsQtySelect>
    <div
      v-show="isShowInputTips"
      class="bsc-cart-item-goods-qty__tips"
    >
      {{ inputTips }}
    </div>
  </div>
</template>

<script name="GoodsQty" setup lang="ts">
import { defineProps, ref, computed, watch, nextTick } from 'vue'
import sui_icon_more_down_14px from '../../components/icon/sui_icon_more_down_14px.vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import GoodsQtySelect from './goods-qty-select.vue'
import GoodsQtyOption from './goods-qty-option.vue'
import type { GoodsQty } from '../../../../types/laptop/index.ts'

/**
 * 数量编辑器组件V2
 */

// hooks
const registerExpose = useInjectRegisterExpose()

const props = withDefaults(defineProps<GoodsQty.Props>(), {
  isShow: true,
  isSoldoutByInventory: false,
  value: 1,
  min: 1,
  max: 99,
  maxOptionNumber: 10,
  isShowMask: false,
  isClick: true,
  inputTips: '',
  scrollParent: '',
  width: '87px',
  height: '24px',
  padding: '0 12px',
  tipsMaxWidth: '330px',
  autoAlignByShowInputTips: true,
})

// events
const emit = defineEmits(['change', 'report', 'expose'])

const isShowSelect = ref(false)
const qtyContentRef = ref(null)
const inputRef = ref(null)
const selectRef = ref(null)
const placement = ref('bottom')
const quantity = ref('')
const isShowInputTips = ref(false)
watch(() => props.value, (val) => {
  // @ts-ignore
  quantity.value = +val
}, {
  immediate: true,
})

const getOption = (value) => {
  return {
    label: value > props.maxOptionNumber ? `${props.maxOptionNumber}+` : value + '',
    value: value > props.maxOptionNumber ? '' : value,
    disabled: value > props.max,
  }
}
const options = computed(() => {
  const list:any = []
  // @ts-ignore
  let num = Math.max(props.max, props.value)
  num = Math.min(num, props.maxOptionNumber + 1)

  for (let i = 1; i <= num; i++) {
    let option = getOption(i)
    // @ts-ignore
    list.push(option)
  }
  return list
})

const isInputError = computed(() => {
  // @ts-ignore
  return props.isSoldoutByInventory && quantity.value > props.max && !props.isShowMask
})

const styles = computed(() => {
  const paddingList = props.padding.trim().split(' ')
  return {
    '--width': props.width,
    '--height': props.height,
    '--padding': props.padding,
    '--tips-max-width': props.tipsMaxWidth,
    '--select-arrow-right': paddingList[1] || paddingList[0] || '12px',
  }
})

const selectHeight = computed(() => {
  return 24 * options.value.length
})
const getPlacement = (el) => {
  const { top, bottom } = el.getBoundingClientRect()
  if (props.scrollParent) {
    const scrollParentEl = document.querySelector(props.scrollParent)
    if (scrollParentEl) {
      const { top: mTop, bottom: mBottom } = scrollParentEl.getBoundingClientRect()
      const spaceAbove = top - mTop - selectHeight.value
      const spaceBelow = mBottom - bottom - selectHeight.value
      if (spaceAbove < 12 && spaceBelow < 12) {
        return 'bottom'
      }
      return spaceAbove > spaceBelow ? 'top' : 'bottom'
    }
  }
  const { innerHeight } = window
  const spaceBelow = innerHeight - bottom - selectHeight.value
  if (spaceBelow < 12) {
    return 'top'
  } else {
    return 'bottom'
  }
}

const clickSuffix = async () => {
  if (!props.isClick) return
  if (!isShowSelect.value) {
    placement.value = getPlacement(qtyContentRef.value)
    isShowSelect.value = true
    await nextTick()
    // @ts-ignore
    selectRef.value.focus()
  } else {
    isShowSelect.value = false
  }
}
const clickInputWrapper = (e) => {
  if (!props.isClick) return
  if (e.target.tagName === 'LABEL') {
    // @ts-ignore
    const range = quantity.value > 9 ? 2 : 1
    // @ts-ignore
    inputRef.value.setSelectionRange(range, range)
    // @ts-ignore
    inputRef.value.focus()
  }
  emit('report', { type: 'clickInput' })
}
const clickOption = (option) => {
  const { value, disabled = false } = option
  if (disabled) return
  if (value === '') {
    isShowSelect.value = false
    quantity.value = ''
    // @ts-ignore
    inputRef.value.focus()
    isShowInputTips.value = !isShowInputTips.value
    emit('report', { type: 'clickNullValueOption' })
    return
  }
  if (value === quantity.value) {
    isShowSelect.value = false
    return
  }
  quantity.value = value
  isShowSelect.value = false
  emit('change', { quantity: quantity.value, action: 'select' })
}
const handleInputBlur = () => {
  isShowInputTips.value = false
  if (quantity.value === '') {
    // @ts-ignore
    quantity.value = props.value
    return
  }
  // @ts-ignore
  quantity.value = +quantity.value
  // @ts-ignore
  if (quantity.value === +props.value) {
    return
  }
  emit('change', { quantity: quantity.value, action: 'input' })
}
const handleInput = (e) => {
  quantity.value = e.target.value.replace(/\D/g, '')

  if (quantity.value === '0') {
    quantity.value = ''
  }
  // @ts-ignore
  if (quantity.value > props.max) {
    // @ts-ignore
    quantity.value = props.max
  }
  isShowInputTips.value = false
}

const handleInputEnterKeyup = () => {
  if (quantity.value === '' || +quantity.value === +props.value) {
    return
  }
  // @ts-ignore
  inputRef.value.blur()
}
const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('GoodsQty', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-goods-qty {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #222;
  font-size: 12px;
  &.bsc-cart-item-goods-qty_mt {
    margin-top: 12px;
  }
  .bsc-cart-item-goods-qty__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding);
    width: var(--width);
    height: var(--height);
    border-radius: var(--height);
    border: 0.5px solid #CCC;
    /* stylelint-disable-next-line selector-max-specificity */
    &:not(.bsc-cart-item-goods-qty__content_mask):hover,
    &:focus-within {
      border-color: #000;
    }
  }
  .bsc-cart-item-goods-qty__content_mask {
    border-color: #E5E5E5;
    background-color: #F6F6F6;
    color: #CCC;
    cursor: not-allowed;
    .bsc-cart-item-goods-qty__label,
    .bsc-cart-item-goods-qty__input,
    .bsc-cart-item-goods-qty__suffix {
      color: #CCC;
      cursor: not-allowed;
      pointer-events: none;
    }

  }
  .bsc-cart-item-goods-qty__input-wrap {
    flex: 1;
    display: flex;
    align-items: center;
    width: 0;
  }
  .bsc-cart-item-goods-qty__input {
    flex: 1;
    width: 0;
    border: none;
    outline: none;
    text-align: center;
    font-weight: 700;
    background-color: transparent;
    &.bsc-cart-item-goods-qty__input_error {
      color: @sui_color_unusual;
    }
    &::placeholder {
      color: #CCC;
      opacity: 1; /* Firefox 需要设置不透明度 */
    }

    /* Firefox 18- */
    &:-moz-placeholder {
      color: #CCC;
      opacity: 1;
    }

    /* Internet Explorer 10-11 */
    :-ms-input-placeholder {
      color: #CCC;
    }

    /* Edge */
    ::-ms-input-placeholder {
      color: #CCC;
    }

    /* Safari and other WebKit-based browsers */
    ::-webkit-input-placeholder {
      color: #CCC;
    }
  }
  .bsc-cart-item-goods-qty__suffix {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    &.bsc-cart-item-goods-qty__suffix_actived {
      transform: rotate(-180deg);
    }
  }
  .bsc-cart-item-goods-qty__tips {
    position: relative;
    left: calc(50% - calc(var(--width) / 2));
    margin: 2px auto 0;
    max-width: var(--tips-max-width);
    font-size: 10px;
    color: #999;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
