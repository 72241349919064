import type { GoodsCustomContent } from '../../../../types/laptop/index.ts'
import { isCustomizationProduct } from '../../utils/isCustomizationProduct'

/**
 * GoodsCustomContent 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }): GoodsCustomContent.Props {
  // 自定义商品
  const isCustomGoods = isCustomizationProduct(data.value)
  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  return {
    isShow: isCustomGoods && !isInvalidItem,
    isShowMask: isNewcomerItem,
    btnText: language.value.SHEIN_KEY_PC_27387,
    isClick: true,
  }
}
