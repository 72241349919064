import type { VoucherTag } from '../../../../../types/laptop/index.ts'

/**
 * VoucherTag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件 props
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): VoucherTag.Props {
  const info = data.value.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'categoryVoucher') || null

  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  const isShowMask = (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem

  return {
    isShow: !!info,
    isShowMask,
    text: info?.view?.text || '',
    tip: info?.data?.categoryVoucher?.tips,
    tipDisabled: isShowMask,
    tipAppendToBody: true,
    subText: info?.data?.categoryVoucher?.applyText || '',
    suffixIcon: info?.view?.suffixIcon || '',
    bgColor: info?.view?.bgColor || '',
    textColor: info?.view?.textColor || '',
    maxWidth: '100%',
  }
}

export function getEvents() {
  return {}
}
