<template>
  <div
    :style="styles"
    class="bsc-cart-item-goods-price-v1"
  >
    <span
      v-if="isShowPromotionIcon"
      class="bsc-cart-item-goods-price-v1__promotion-icon"
    >
      <PromotionIcon
        :is-show-premium-member-icon="isShowPremiumMemberIcon"
        :is-show-time-icon="isShowTimeIcon"
        :is-show-app-only-icon="isShowAppOnlyIcon"
        :is-show-brand-sale-icon="isShowBrandSaleIcon"
        :is-show-flash-sale-icon="isShowFlashSaleIcon"
        :premium-member-icon-color="premiumMemberIconColor"
        :time-icon-color="timeIconColor"
        :app-only-icon-color="appOnlyIconColor"
        :brand-sale-icon-color="brandSaleIconColor"
        :flash-sale-icon-color="flashSaleIconColor"
      />
    </span>
    <SheinVipIcon
      v-if="isShowSheinVipIcon"
      class="bsc-cart-item-goods-price-v1__shein-vip-icon"
    />
    <!-- 销售价 -->
    <span class="bsc-cart-item-goods-price-v1__sale-price">
      <NumberRoll
        :amount="salePrice"
        :box-width="9"
        :css-right="true"
      />
    </span>
    <span
      :class="otherPriceClasses"
    >
      <!-- 划线价 / RRP -->
      <span
        v-if="isShowOriginalPrice"
        class="bsc-cart-item-goods-price-v1__original-price"
      >
        <del>{{ originalPrice }}</del>
        <span v-if="isShowOriginalPriceText">&nbsp;{{ originPriceText }}</span>
        <span
          v-if="isShowOriginalPricePopover"
          class="bsc-cart-item-goods-price-v1__original-price-popover"
        >
          <SPopover
            trigger="hover"
            theme="dark"
            :show-close-icon="true"
            :outside-close="false"
            :content="originPricePopoverText"
          >
            <template #reference>
              <sui_icon_info_12px_1 />
            </template>
          </SPopover>
        </span>
      </span>
      <!-- 30 天最低价 / RRP -->
      <span
        v-if="isShowSuggestPrice"
        class="bsc-cart-item-goods-price-v1__suggest-price"
      >
        <del>{{ suggestPrice }}</del>
        <span v-if="isShowSuggestPriceText">&nbsp;{{ suggestPriceText }}</span>
      </span>
    </span>
  </div>
</template>

<script name="GoodsPriceV1" setup lang="ts">
import { computed } from 'vue'
import sui_icon_info_12px_1 from '../../components/icon/sui_icon_info_12px_1.vue'
import NumberRoll from '../../components/number-roll/index.vue'
import { SPopover } from '@shein-aidc/sui-popover/laptop'
import PromotionIcon from './components/promotion-icon.vue'
import SheinVipIcon from './components/shein-vip-icon.vue'
import type { GoodsPrice } from '../../../../types/laptop/index.ts'

/**
 * 商品行价格组件
 */

// props
const props = withDefaults(defineProps<GoodsPrice.Props>(), {
  salePrice: '',
  salePriceColor: '#000',
  isShowMask: false,
  isShowOriginalPrice: false,
  isShowOriginalPriceText: false,
  isShowOriginalPricePopover: false,
  originalPrice: '',
  originPriceText: '',
  originPriceColor: '',
  originPriceCrossed: false,
  originPricePopoverText: '',
  isShowSuggestPrice: false,
  isShowSuggestPriceText: false,
  suggestPrice: '',
  suggestPriceText: '',
  suggestPriceColor: '',
  suggestPriceCrossed: false,
  isShowSheinVipIcon: false,
  isShowPromotionIcon: false,
  isShowPremiumMemberIcon: false,
  isShowTimeIcon: false,
  isShowAppOnlyIcon: false,
  isShowBrandSaleIcon: false,
  isShowFlashSaleIcon: false,
  premiumMemberIconColor: '',
  timeIconColor: '',
  appOnlyIconColor: '',
  brandSaleIconColor: '',
  flashSaleIconColor: '',
})

// data
// computed
const styles = computed(() => ({
  '--sale-price-color': props.salePriceColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--original-price-color': props.originPriceColor,
  '--original-price-crossed': props.originPriceCrossed ? 'line-through' : 'none',
  '--suggest-price-color': props.suggestPriceColor,
  '--suggest-price-crossed': props.suggestPriceCrossed ? 'line-through' : 'none',
}))
const otherPriceClasses = computed(() => ({
  'bsc-cart-item-goods-price-v1__other-price': true,
  // 不展示气泡时提示图标时整体文案进行溢出隐藏
  'bsc-cart-item-goods-price-v1__other-price_overflow': !props.isShowOriginalPricePopover,
}))

</script>

<style lang="less">
.bsc-cart-item-goods-price-v1 {
  display: inline-flex;
  align-items: baseline;
  opacity: var(--mask-opacity);
  max-width: 100%;
  &__promotion-icon {
    align-self: center;
    margin-right: 2px;
    font-size: 0;
    flex-shrink: 0;
    height: 16px;
  }
  &__romwe-vip-price {
    align-self: baseline;
    flex-shrink: 0;
  }
  &__shein-vip-icon {
    align-self: baseline;
    flex-shrink: 0;
  }
  &__sale-price {
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    font-weight: bold;
    color:var(--sale-price-color);
  }
  &__other-price {
    display: inline-flex;
    width: 100%;
    overflow: hidden;
    &:not(.bsc-cart-item-goods-price-v1__other-price_overflow) {
      .bsc-cart-item-goods-price-v1__original-price {
        width:fit-content;
        overflow: hidden;
      }
      .bsc-cart-item-goods-price-v1__original-price del {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &_overflow {
      display: inline;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      .bsc-cart-item-goods-price-v1__original-price {
        display: inline;
      }
    }
  }
  &__original-price {
    display: inline-flex;
    margin-left: 2px;
    font-size: 12px;
    color: var(--original-price-color, #767676);
    del {
      text-decoration: var(--original-price-crossed);
    }
  }
  &__original-price-popover {
    margin-left: 2px;
    width: 14px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    /deep/ .sui-popover__trigger {
      font-size: 0;
    }
  }
  &__suggest-price {
    display: inline;
    margin-left: 6px;
    font-size: 12px;
    color: var(--suggest-price-color, #767676);
    del {
      text-decoration: var(--suggest-price-crossed);
    }
  }
}
</style>
