import DiscountNormalTag from './discount-normal-tag.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useDiscountNormalTag = createHelper('DiscountNormalTag', getProps)

export {
  DiscountNormalTag,
  useDiscountNormalTag,
}
